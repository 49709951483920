import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import { ExternalHyperlink } from '@workday/canvas-kit-react/button';
import { InformationHighlight } from '@workday/canvas-kit-docs';
import { DepthUsageTabs } from '../../../src/components/content/tokens/DepthUsageTabs';
import { DepthTokens } from '../../../src/components/content/tokens/TokenDepthGrid';
import { getInternalExternalURL } from '../../../src/utils/url';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PackageInfo = makeShortcode("PackageInfo");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <InformationHighlight mdxType="InformationHighlight">
  <InformationHighlight.Icon style={{
        width: '24px'
      }} />
  <InformationHighlight.Heading>Looking for our old depth tokens?</InformationHighlight.Heading>
  <InformationHighlight.Body>
    While we still support our old tokens, we’re migrating our documentation and usage guidance to
    reflect our new Tokens Web. It is <strong>not</strong> currently a requirement to update all
    your tokens to use v11, but we <strong>strongly</strong> encourage you to begin migrating as you
    can. If you're an engineer looking to migrate to our new tokens, we wrote a handy{' '}
    <ExternalHyperlink href={'https://github.com/Workday/canvas-tokens/discussions/77'} mdxType="ExternalHyperlink">
      migration guide
    </ExternalHyperlink>
    for you.
  </InformationHighlight.Body>
  <InformationHighlight.Link href={getInternalExternalURL('/v10/tokens/depth')}>
    View v10 Depth Token Docs.
  </InformationHighlight.Link>
    </InformationHighlight>
    <PackageInfo storybook="https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-depth--docs" figma="https://www.figma.com/design/GmIqhQ7KqX6WQZFimdn756/Canvas-Tokens-v2?node-id=21702-31173&t=zaRL8ALlvmcTsWQc-4" package="@workday/canvas-tokens-web" mdxType="PackageInfo" />
    <h2 {...{
      "id": "usage-guidance"
    }}>{`Usage Guidance`}</h2>
    <p>{`Depth helps the user understand the spatial relationship between objects by utilizing differences in
shadows. The closer an object is to another surface the smaller and harder the shadow becomes. As
that object moves away from the surface the shadow will grow and become softer. We can use this to
our advantage by placing more critical or important objects closer to the user.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/daae07a10b927bd61660e1a67481b978/daed9/depth-objects-elevation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.486486486486484%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of two different shadows of varying depth to indicate different elevation.",
            "title": "Image of two different shadows of varying depth to indicate different elevation.",
            "src": "/static/daae07a10b927bd61660e1a67481b978/50383/depth-objects-elevation.png",
            "srcSet": ["/static/daae07a10b927bd61660e1a67481b978/1efb2/depth-objects-elevation.png 370w", "/static/daae07a10b927bd61660e1a67481b978/50383/depth-objects-elevation.png 740w", "/static/daae07a10b927bd61660e1a67481b978/daed9/depth-objects-elevation.png 1426w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We also use depth in our UI to help guide and focus the user's eye on certain elements by adding
contrast or definition with various shadows. An example of this would be when we add depth to
certain temporary elements, like a menu.`}</p>
    <h3 {...{
      "id": "depth-values"
    }}>{`Depth Values`}</h3>
    <p>{`Our depth styles utilize two different shadows to create a more realistic appearing shadow. A
sharper and shorter shadow that is indicative of a strong direct light. And a second softer and
longer shadow resembling reflected or ambient lighting.`}</p>
    <h3 {...{
      "id": "do-you-really-need-a-shadow"
    }}>{`Do You Really Need a Shadow?`}</h3>
    <p>{`Not every piece of our UI needs to have depth. Depth should be used minimally and intentionally to
help improve the experience. When used improperly, depth can lead to added visual noise and weight
in the UI resulting in a crowded, ineffective, and confusing experience for our users. It is okay to
use zero depth for most of our UI. If certain elements of an experience need increased definition
there are alternative ways in achieving this through the use of color or borders. Typically we do
not recommend combining these alternative methods with our defined depth values.`}</p>
    <h2 {...{
      "id": "recommended-usage"
    }}>{`Recommended Usage`}</h2>
    <p>{`Choose from the depth tokens below to see their recommended usage.`}</p>
    <DepthUsageTabs mdxType="DepthUsageTabs" />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Depth Token`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Shadow 1 (x, y, blur, color)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Shadow 2 (x, y, blur, color)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Depth 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.0625rem`}</inlineCode>{` `}<inlineCode parentName="td">{`.25rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.12)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.0625rem`}</inlineCode>{` `}<inlineCode parentName="td">{`.25rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.12)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Depth 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.125rem`}</inlineCode>{` `}<inlineCode parentName="td">{`.5rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.12)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.25rem`}</inlineCode>{` `}<inlineCode parentName="td">{`1rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.08)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Depth 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.1875rem`}</inlineCode>{` `}<inlineCode parentName="td">{`.75rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.12)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.375rem`}</inlineCode>{` `}<inlineCode parentName="td">{`1.5rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.08)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Depth 4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` .`}<inlineCode parentName="td">{`25rem`}</inlineCode>{` `}<inlineCode parentName="td">{`1rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.12)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.5rem`}</inlineCode>{` `}<inlineCode parentName="td">{`2rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.08)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Depth 5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.3125rem`}</inlineCode>{` `}<inlineCode parentName="td">{`1.25rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.12)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.625rem`}</inlineCode>{` `}<inlineCode parentName="td">{`2.5rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.08)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Depth 6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.375rem`}</inlineCode>{` `}<inlineCode parentName="td">{`1.5rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.12)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}<inlineCode parentName="td">{`.75rem`}</inlineCode>{` `}<inlineCode parentName="td">{`3rem`}</inlineCode>{` `}<inlineCode parentName="td">{`rgba(31,38,46,.08)`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "dos-and-donts"
    }}>{`Dos and Don'ts`}</h3>
    <SideBySide mdxType="SideBySide">
      <Suggestion guidance="Do allow objects with a lower elevation to pass underneath objects with a higher elevation." mdxType="Suggestion">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "629px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f1d37f7c8acfd3b9aa50601d6708515f/63a68/depth-do-image.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "61.891891891891895%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image demonstrating how to use a objects with lower elevation and higher elevation.",
                "title": "Image demonstrating how to use a objects with lower elevation and higher elevation.",
                "src": "/static/f1d37f7c8acfd3b9aa50601d6708515f/63a68/depth-do-image.png",
                "srcSet": ["/static/f1d37f7c8acfd3b9aa50601d6708515f/1efb2/depth-do-image.png 370w", "/static/f1d37f7c8acfd3b9aa50601d6708515f/63a68/depth-do-image.png 629w"],
                "sizes": "(max-width: 629px) 100vw, 629px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Suggestion>
      <Suggestion status="error" guidance="Don’t mix depth styles for similar or equal objects." mdxType="Suggestion">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "629px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d38adf0bf5dc330b145d4b9d8d92d776/63a68/depth-don't-image.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "61.891891891891895%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image demonstrating how NOT to mix depth for similar or equal objects.",
                "title": "Image demonstrating how NOT to mix depth for similar or equal objects.",
                "src": "/static/d38adf0bf5dc330b145d4b9d8d92d776/63a68/depth-don't-image.png",
                "srcSet": ["/static/d38adf0bf5dc330b145d4b9d8d92d776/1efb2/depth-don't-image.png 370w", "/static/d38adf0bf5dc330b145d4b9d8d92d776/63a68/depth-don't-image.png 629w"],
                "sizes": "(max-width: 629px) 100vw, 629px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Suggestion>
    </SideBySide>
    <h2 {...{
      "id": "web-examples"
    }}>{`Web Examples`}</h2>
    <h3 {...{
      "id": "javascript--typescript"
    }}>{`Javascript / Typescript`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// styles.ts
import {system} from '@workday/canvas-tokens-web';

const styles = {
  boxShadow: \`var(\${system.depth[2]})\`,
};
`}</code></pre>
    <h3 {...{
      "id": "css"
    }}>{`CSS`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`// styles.css
@import '@workday/canvas-tokens-web/css/system/_variables.css';
.card {
  background-color: var(--cnvs-sys-depth-2);
}
`}</code></pre>
    <DepthTokens mdxType="DepthTokens" />
    <h2 {...{
      "id": "ios-examples"
    }}>{`iOS Examples`}</h2>
    <p>{`Coming soon!`}</p>
    <h2 {...{
      "id": "android-examples"
    }}>{`Android Examples`}</h2>
    <p>{`Coming soon!`}</p>
    <h2 {...{
      "id": "accessibility-guidelines"
    }}>{`Accessibility Guidelines`}</h2>
    <p>{`Make sure tooltips, overlays, and modals are visually distinct from other content through supporting
icons, color usage, appropriate depth usage, and other visual elements Make sure borders of
tooltips, overlays, and modals meet 3:1 contrast with their backgrounds`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      