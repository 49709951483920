
  import * as React from 'react';
import {system} from '@workday/canvas-tokens-web';
import {TokenGrid, formatJSVar} from './TokenGrid';

interface DepthToken {
  /** The name of the CSS variable */
  cssVar: string;
  /** The formatted name of the JS variable */
  jsVar: React.ReactNode;
  /** The actual string value of the token */
  value: string;
}

const depthTokens: DepthToken[] = Object.entries(system.depth).map(([level, varName]) => {
  const value =
    typeof window !== 'undefined'
      ? getComputedStyle(document.documentElement).getPropertyValue(varName)
      : '';

  return {
    cssVar: varName,
    jsVar: formatJSVar(`system.depth.${level}`),
    value: value.split('),').join('),\n'),
  };
});

export function DepthTokens() {
  return (
    <TokenGrid
      caption="depth tokens"
      headings={['Sample', 'CSS Variable', 'JS Variable', 'Values']}
      rows={depthTokens}
    >
      {token => (
        <>
          <TokenGrid.RowItem>
            <TokenGrid.Sample style={{boxShadow: token.value}} />
          </TokenGrid.RowItem>
          <TokenGrid.RowItem>
            <TokenGrid.MonospaceLabel>{token.cssVar}</TokenGrid.MonospaceLabel>
          </TokenGrid.RowItem>
          <TokenGrid.RowItem>
            <TokenGrid.MonospaceLabel>{token.jsVar}</TokenGrid.MonospaceLabel>
          </TokenGrid.RowItem>
          <TokenGrid.RowItem>
            {token.value.split('),').map((item, i) => (
              <span key={i}>{i === 0 ? `${item})` : item}</span>
            ))}
          </TokenGrid.RowItem>
        </>
      )}
    </TokenGrid>
  );
}

  DepthTokens.__RAW__ = "import * as React from 'react';\nimport {system} from '@workday/canvas-tokens-web';\nimport {TokenGrid, formatJSVar} from './TokenGrid';\n\ninterface DepthToken {\n  /** The name of the CSS variable */\n  cssVar: string;\n  /** The formatted name of the JS variable */\n  jsVar: React.ReactNode;\n  /** The actual string value of the token */\n  value: string;\n}\n\nconst depthTokens: DepthToken[] = Object.entries(system.depth).map(([level, varName]) => {\n  const value =\n    typeof window !== 'undefined'\n      ? getComputedStyle(document.documentElement).getPropertyValue(varName)\n      : '';\n\n  return {\n    cssVar: varName,\n    jsVar: formatJSVar(`system.depth.${level}`),\n    value: value.split('),').join('),\\n'),\n  };\n});\n\nexport function DepthTokens() {\n  return (\n    <TokenGrid\n      caption=\"depth tokens\"\n      headings={['Sample', 'CSS Variable', 'JS Variable', 'Values']}\n      rows={depthTokens}\n    >\n      {token => (\n        <>\n          <TokenGrid.RowItem>\n            <TokenGrid.Sample style={{boxShadow: token.value}} />\n          </TokenGrid.RowItem>\n          <TokenGrid.RowItem>\n            <TokenGrid.MonospaceLabel>{token.cssVar}</TokenGrid.MonospaceLabel>\n          </TokenGrid.RowItem>\n          <TokenGrid.RowItem>\n            <TokenGrid.MonospaceLabel>{token.jsVar}</TokenGrid.MonospaceLabel>\n          </TokenGrid.RowItem>\n          <TokenGrid.RowItem>\n            {token.value.split('),').map((item, i) => (\n              <span key={i}>{i === 0 ? `${item})` : item}</span>\n            ))}\n          </TokenGrid.RowItem>\n        </>\n      )}\n    </TokenGrid>\n  );\n}\n";